<template>
<div class="main">
   
    
     <!-- <router-link to="/einsaetze">Home</router-link>
     <router-link class="btn btn-success" to="/einsaetze">Zurück</router-link>
     <a @click="$router.go(-1)">back</a> -->
 <el-tabs type="border-card">
  <el-tab-pane label="Stammdaten">

    <form v-if="eintrag" @submit.prevent="saveEintrag();">

             <div class="col-12">
          <label>Kategorie</label>
           <select v-model="eintrag.kategorie" class="form-control">
             <option value="">Bitte Kategorie wählen</option>
             <option value="Service">Service</option>
             <option value="Reparaturen">Reparaturen</option>
             <option value="Unterhaltung">Unterhaltung</option>
             <option value="Halle">Halle</option>
             <option value="Defekte">Defekte</option>
             <option value="Sonstiges">Sonstiges</option>
             <option value="Büro">Büro</option>
             <option value="SUK">SUK</option>
             <option value="Gasölbestand">Gasölbestand</option>
             <option value="Bunkern">Bunkern</option>
             <option value="Mitarbeiter">Mitarbeiter</option>
              <option value="Arbeitsnachweis">Arbeitsnachweis</option>
             <option value="Wasserständer">Wasserständer</option>
             <option value="Hochwasser">Hochwasser</option>
             <option value="Niedrigwasser">Niedrigwasser</option>
           </select>
        </div>


        <div class="col-12">
          <label>Datum</label><br />
            <el-date-picker
              v-model="eintrag.datum"
              type="datetime"
              format="DD.MM.YYYY HH:mm:ss"
              placeholder="Bitte ein Datum auswählen">
            </el-date-picker>
            <p />



          <button type="button" class="btn btn-secondary" v-bind:key="shortcut._id" v-for="shortcut in kuerzelComputed" @click="appendText(shortcut)">{{shortcut.kuerzel}}</button>
          <p />
          <label>Text</label>
            <textarea style="height:8em" v-model="eintrag.text" class="form-control"></textarea>
        </div>

      <div class="col-12">
        <label>Dauer</label>
        <input type="number" class="form-control" v-model="eintrag.dauer" step="0.1">
        </div>


        <div class="col-12">
          <label>Faehre</label>
           <select v-model="eintrag.faehre" class="form-control">
             <option value="">Bitte Kategorie wählen</option>
             <option value="linzremagen">Linz-Remagen</option>
             <option value="stjohannes">St. Johannes</option>
             <option value="nixe">Nixe</option>
             <option value="stmartin">St.Martin</option>
             <option value="sonstiges">Sonstiges</option>
           </select>
        </div>


                <div class="col-12">
          <label>Wiedervorlage</label><br />
            <el-date-picker
              v-model="eintrag.wiedervorlage"
              type="date"
              format="DD.MM.YYYY"
              placeholder="Bitte ein Datum auswählen">
            </el-date-picker>
        </div>
   

       
        <div class="col-12 text-left">
          <button type="submit" class="btn btn-primary">Speichern</button>
    <button class="btn btn-success" @click="$router.go(-1)">Abbrechen</button>
    <button type="button" class="btn btn-success" @click="reload()"><i class="el-icon-refresh"></i></button>
    <button type="button" style="float:right" class="btn btn-danger" @click="deleteEintrag(attachment)"><i class="el-icon-delete"></i></button> 
        </div>
     </form>

  </el-tab-pane>

  <el-tab-pane label="Anlagen" v-if="eintrag">



                  <el-upload
                  
          :on-change="handleAvatarSuccess" 
          :on-success="reload"
          :before-upload="beforeAvatarUpload"
          :show-file-list="true"
          :data="{eintrag_id:eintrag._id,subdir:'fotos',isFoto:true}"
          class="upload-demo"
          drag
          action="https://logbuch.format-c.info/fileUpload"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            Dateien hierhin ziehen oder <em>klicken zum Upload</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">
              <!-- jpg/png files with a size less than 500kb -->
            </div>
          </template>
        </el-upload>


          <table class="table table-striped">
    <tr>
      <th>Name</th>
      <th>Größe</th>
      <th></th>
    </tr>
    <tr class="cp" :key="attachment" v-for="attachment in eintrag.attachments"   >
      <td @click="download(attachment)">{{attachment.name}}</td>
      <!-- <td><a :href="'https://logbuch.format-c.info/download/'+eintrag._id+'/'+attachment.name+''" target="_blank">{{attachment.name}}</a></td> -->
      <td>{{Math.round((attachment.size*100) / 1024 /1024 )/100}} MB</td>
      <td @click="deleteAttachment(attachment)"><button class="btn btn-danger"><i class="el-icon-delete"></i></button> </td>
    </tr>
  </table>
  




  </el-tab-pane>

</el-tabs >
  
</div>   
</template>



<script>

// import AppAutocomplete from "../components/AppAutocomplete"
import { inject, ref,computed } from 'vue'
import {  useRoute } from 'vue-router'
import router from "../router";
export default{
    components:{
        // AppAutocomplete,
        // 
    },
   filters: {
  germanDate: function (value) {
    
    return value+"xx"
  }
},

    setup(){

      
        // Injects
        const moment =inject("moment")
        // const post =inject("post")
        const web =inject("web")
        const storage =inject("storage")
        const route = useRoute()

        let shortcuts=storage.get("shortcuts");

        let eintrag = ref()
        let eintrag_id = route.params.eintrag_id;
        console.log("route")
        console.log(route.params.eintrag_id);
         
        // const lodash =inject("lodash")
        // const storage =inject("storage")
        // const notify =inject("notify")

        moment()
        function  reload(){
           
            web.get("/loadeintrag/"+eintrag_id,function(data){
                // console.log(data.einsatz)
                eintrag.value = data.eintrag
            })
            
        }
        reload()

        function saveEintrag()  {
            web.post("/saveEintrag",{eintrag:eintrag.value},function(data){
                console.log(data);
                eintrag.value=data.updatedEintrag
                goTo("/logbuch")
            })
        }
     
        function goTo(target) {
          router.push({ path: target });
        }


       
        const kuerzelComputed = computed(() => {
          
          console.log(shortcuts)
          console.log(eintrag)
          return shortcuts.filter( shortcut => shortcut.kategorie==eintrag.value.kategorie
            
            
          );
        });

        function appendText(shortcut){

          if(typeof(eintrag.value.text)=="undefined"){
            eintrag.value.text = shortcut.text
          }else{

            eintrag.value.text = eintrag.value.text+"\n"+shortcut.text
          }
        }

        function download(attachment){
          window.open('https://logbuch.format-c.info/download/'+eintrag.value._id+'/'+attachment.name)
        }


        function deleteAttachment(attachment){
          if(!confirm("Sicher?")) return;

          web.post("/deleteAttachment",{eintrag_id:eintrag.value._id,attachment:attachment},function(data){
                console.log(data);
                console.log("lade neu")
                reload()
            })
        }

        function deleteEintrag(){
          if(!confirm("Sicher?")) return;

          web.post("/deleteEintrag",{eintrag_id:eintrag.value._id},function(data){
                console.log(data);
                goTo("/logbuch")
                
            })
        }


        

        return {
            eintrag:eintrag,
            deleteEintrag,
            reload,
            saveEintrag,
            kuerzelComputed,
            appendText,
            download,
            deleteAttachment

        }
       

    },
     mounted (){
            // alert(1)
        }
}

</script>

<style scoped>

 .main{
        margin:20px;
    }


</style>