<template>
  <div class="main">
    <!-- <router-link class="nav-link" to="/qrscanner"  tag="button">Jetzt QR Code Scannen</router-link>  -->


<div>
  <button class="btn btn-secondary btn-bar" @click="setFaehre('linzremagen')" v-bind:class="{ selected: faehre=='linzremagen'}" >Linz-Remagen</button>
  <button class="btn btn-secondary btn-bar" @click="setFaehre('stjohannes')"  v-bind:class="{ selected: faehre=='stjohannes'}">St. Johannes</button>
  <button class="btn btn-secondary btn-bar" @click="setFaehre('nixe')"        v-bind:class="{ selected: faehre=='nixe'}">Nixe</button>
  <button class="btn btn-secondary btn-bar" @click="setFaehre('stmartin')"    v-bind:class="{ selected: faehre=='stmartin'}" >St. Martin</button>
  <button class="btn btn-secondary btn-bar" @click="setFaehre('sonstiges')"   v-bind:class="{ selected: faehre=='sonstiges'}" >Sonstiges</button>
  <button class="btn btn-secondary btn-bar" @click="setFaehre('buero')"   v-bind:class="{ selected: faehre=='buero'}" >Büro</button>
</div>



<div >
  <hr />
  <h2 @click="reload()">Wiedervorlagen</h2>


<div v-if="wiedervorlagen.length == 0" >
  Es gibt keine fälligen Wiedervorlagen
  </div>

<div class="cp" :key="wiedervorlage._id" v-for="wiedervorlage in wiedervorlagen"  @click="goTo('/eintrag/'+wiedervorlage._id)">
  <div class="datum">Fällig am {{getDate(wiedervorlage.wiedervorlage)}}</div>
  <div class="kategorie">{{wiedervorlage.kategorie}}</div>
  <div class="text preformatted">{{wiedervorlage.text}}</div>
</div>

  <!-- <table class="table table-striped">
    <tr>
      <th>Fällig</th>
      <th>Text</th>
      <th>Typ</th>
    </tr>
    <tr class="cp" :key="wiedervorlage._id" v-for="wiedervorlage in wiedervorlagen"  @click="goTo('/eintrag/'+wiedervorlage._id)">
      <td>{{getDate(wiedervorlage.wiedervorlage)}}</td>
      <td class="preformatted">{{wiedervorlage.text}}</td>
      <td>{{wiedervorlage.kategorie}}</td>
    </tr>
  </table>-->



  </div> 





    
  </div>
</template>
<script>
import { ref,inject } from "vue";
//   import { ElMessageBox } from 'element-plus';
import router from "../router";
export default {
  components: {},

  setup() {
    const storage = inject("storage");
    const web = inject("web");
    const moment =inject("moment")
    let faehreWasSet = storage.get("faehreWasSet")

    

    console.log("faehreWasSet: "+faehreWasSet);
    let faehre=ref()
    let wiedervorlagen=ref([])
    faehre.value=storage.get("faehre")

    function setFaehre(faehre) {
      storage.set("faehre",faehre);
      window.location.reload()
      storage.set("faehreWasSet",true)
      // goTo("logbuch")
    }
 
    function reload(){
      web.get("/wiedervorlagen",function(data){
        wiedervorlagen.value=data.wiedervorlagen
      })

    }
    reload()


    function goTo(target) {
      router.push({ path: target });
    }


    if(faehreWasSet!==null){
      storage.delete("faehreWasSet")
      goTo("logbuch")
    }

  function getDate(date){
    console.log(date)
    return moment(date).format("DD.MM.YYYY")
  }

  function nl2br(string){

    string= string.replace("\n","<br>");
    string= string.replace("\r","<br>");

    return string
  }
    return {
      goTo,
      setFaehre,
      faehre,
      reload,
      wiedervorlagen,
      getDate,
      nl2br
    };
  },
  mounted() {
    // alert(1)
  },
};
</script>

<style scoped>
.selected{
  background-color:#00ce00;
}
.main {
  margin: 10px;
}
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
template {
  padding: 20px;
}
.main {
  margin: 20px;
}
.preformatted {
    /* font-family: monospace; */
    white-space: pre;
}

.btn{
  width:45%;
}

.datum{
  float:left;
  font-weight:bold;
  font-size:12px;
}
.kategorie{
  font-weight:bold;
  float:right;
  font-size:12px;
}
.text{
  clear:both;
  border-top:1px solid #cecece;
  margin-bottom:30px;
}

</style>